import React from 'react'
import "./AnimationReact.css";
const AnimationReact = () => {
  return (
    <div className="main">
    <div className="box">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  )
}

export default AnimationReact